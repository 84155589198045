import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { ColProps, Layout, Row, Col } from 'antd'
const { Content } = Layout

const Container: React.FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const colProps: ColProps = {
    xs: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
    xl: { span: 12 },
  }

  return (
    <Layout>
      <Content
        style={{
          minHeight: '100vh', //edit this to change minimum page height
          padding: '32px 16px',
        }}
      >
        <Row justify="center">
          <Col {...colProps}>{children}</Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default Container
